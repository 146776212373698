<template>
  <div>
    <el-form :inline="true">
      <!-- 添加/搜索区域 -->
      <el-form-item>
        <el-button @click="addnews">{{ $t('xinzeng') }}</el-button>
      </el-form-item>
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="infolist" border style="width: 100%;">
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column prop="title" :label="$t('hq.biaoti')"></el-table-column>
      <el-table-column prop="desc" :label="$t('hq.jianjie')"></el-table-column>
      <el-table-column prop="image" :label="$t('hq.fmtp')">
        <template slot-scope="{ row }">
          <img :src="row.image" alt="" style="max-width:60px;max-height:60px" />
        </template>
      </el-table-column>
      <el-table-column
        prop="overdue_time"
        :label="$t('hq.sfgq')"
        :formatter="is_exprie"
      >
      </el-table-column>
      <el-table-column
        prop="overdue_time"
        :label="$t('hq.gqsj')"
        :formatter="dateFormat"
      ></el-table-column>
      <el-table-column prop="is_overdue" :label="$t('hq.sfyjyx')">
        <template slot-scope="{ row }">
          <span v-if="row.is_overdue === '1'">{{ $t('shi') }}</span>
          <span v-else>{{ $t('fou') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="push_user"
        :label="$t('hq.tsyh')"
      ></el-table-column>
      <el-table-column
        prop="createtime"
        :label="$t('cjsj')"
        :formatter="dateFormat"
      ></el-table-column>
      <el-table-column :label="$t('caozuo')" min-width="120px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('shanchu')"
            placement="top"
            :enterable="false"
          >
            <i class="el-icon-close" @click="del(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 添加产品分类弹窗区域 -->
    <el-dialog
      :title="$t('xinzeng')"
      :visible="dialogvisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="infoform"
        label-width="170px"
        ref="infoform"
        :rules="formrules"
      >
        <el-form-item :label="$t('hq.biaoti')" prop="title">
          <el-input v-model="infoform.title"></el-input>
        </el-form-item>
        <el-form-item :label="$t('hq.jianjie')" prop="desc">
          <el-input v-model="infoform.desc"></el-input>
        </el-form-item>
        <el-form-item :label="$t('hq.fmtp')" prop="image">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/news/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload"
          >
            <img v-if="infoform.image" :src="infoform.image" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item :label="$t('hq.neirong')" prop="content">
          <div id="editor"></div>
        </el-form-item>
        <el-form-item :label="$t('hq.sfyjyx')" prop="is_overdue">
          <el-radio-group v-model="infoform.is_overdue">
            <el-radio label="1">{{ $t('shi') }}</el-radio>
            <el-radio label="0">{{ $t('fou') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="$t('hq.gqsj')"
          prop="overdue_time"
          :rules="
            infoform.is_overdue === '0'
              ? [
                  {
                    type: 'date',
                    required: true,
                    message: '请选择时间',
                    trigger: 'change'
                  }
                ]
              : []
          "
        >
          <el-date-picker
            v-model="infoform.overdue_time"
            type="datetime"
            value-format="timestamp"
            placeholder="选择日期时间"
            :disabled="infoform.is_overdue === '1'"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('hq.sfzjxts')" prop="is_only_push">
          <el-radio-group v-model="infoform.is_only_push">
            <el-radio label="1">{{ $t('shi') }}</el-radio>
            <el-radio label="0">{{ $t('fou') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('hq.tsyh')" prop="push_user">
          <el-select
            multiple
            v-model="infoform.push_user"
            :placeholder="$t('qxz')"
          >
            <el-option
              v-for="item in users"
              :key="item.id"
              :label="item.username"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">{{ $t('tijiao') }}</el-button>
        <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑产品分类弹窗区域 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="infoform"
        label-width="170px"
        ref="infoform"
        :rules="formrules"
      >
        <el-form-item :label="$t('hq.biaoti')" prop="title">
          <el-input v-model="infoform.title"></el-input>
        </el-form-item>
        <el-form-item :label="$t('hq.jianjie')" prop="desc">
          <el-input v-model="infoform.desc"></el-input>
        </el-form-item>
        <el-form-item :label="$t('hq.fmtp')" prop="image">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/news/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload"
          >
            <img v-if="infoform.image" :src="infoform.image" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item :label="$t('hq.neirong')" prop="content">
          <div id="editor"></div>
        </el-form-item>
        <el-form-item :label="$t('hq.sfyjyx')" prop="is_overdue">
          <el-radio-group v-model="infoform.is_overdue">
            <el-radio label="1">{{ $t('shi') }}</el-radio>
            <el-radio label="0">{{ $t('fou') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="$t('hq.gqsj')"
          prop="overdue_time"
          :rules="
            infoform.is_overdue === '0'
              ? [
                  {
                    type: 'date',
                    required: true,
                    message: '请选择时间',
                    trigger: 'change'
                  }
                ]
              : []
          "
        >
          <el-date-picker
            v-model="infoform.overdue_time"
            type="datetime"
            value-format="timestamp"
            placeholder="选择日期时间"
            :disabled="infoform.is_overdue === '1'"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('hq.sfzjxts')" prop="is_only_push">
          <el-radio-group v-model="infoform.is_only_push">
            <el-radio label="1">{{ $t('shi') }}</el-radio>
            <el-radio label="0">{{ $t('fou') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('hq.tsyh')" prop="push_user">
          <el-select
            multiple
            v-model="infoform.push_user"
            :placeholder="$t('qxz')"
          >
            <el-option
              v-for="item in users"
              :key="item.id"
              :label="item.username"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import E from 'wangeditor'
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1
      },
      infolist: [],
      total: 0,
      loading: true,
      // 编辑器
      editor: '',
      // 所有用户ids
      users: [],
      // 添加数据对象
      infoform: {
        title: '',
        content: '',
        desc: '',
        image: '',
        is_overdue: '0',
        overdue_time: '',
        push_user: [],
        is_only_push: '0'
      },
      dialogvisible: false,
      formrules: {
        title: [{ required: true, message: '请填写标题', trigger: 'blur' }],
        content: [{ required: true, message: '请填写内容', trigger: 'blur' }],
        desc: [{ required: true, message: '请填写简介', trigger: 'blur' }],
        image: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        is_overdue: [{ required: true, message: '请选择', trigger: 'change' }],
        is_only_push: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      editdialogvisible: false,
      // 设置图片上传的token
      headerobj: {
        Authorization: window.sessionStorage.getItem('token')
      }
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/news/newslist', {
        params: this.queryinfo
      })
      this.infolist = data.data.list
      this.loading = false
    },
    // 添加新闻弹窗
    async addnews() {
      this.dialogvisible = true
      // 编辑器
      this.$nextTick(v => {
        if (this.editor) return false
        this.editor = new E('#editor')
        this.editor.config.onchange = html => {
          this.infoform.content = html
        }
        this.editor.config.menus = [
          'head',
          'bold',
          'fontSize',
          'italic',
          'underline',
          'strikeThrough',
          'foreColor',
          'link',
          'justify',
          'image',
          'table',
          'undo',
          'redo'
        ]
        this.editor.create()
      })
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/news/addnews')
      if (data) {
        if (data.code === 200) {
          this.users = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 提交添加
    submit() {
      this.$refs.infoform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/news/addnews',
          this.infoform
        )
        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(this.getlang(data.msg))
            this.handleClose()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 关闭添加弹窗
    handleClose() {
      this.$refs.infoform.resetFields()
      this.editor.txt.html('')
      this.dialogvisible = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 格式化时间
    dateFormat(row) {
      const time = row.createtime
      const dt = new Date(parseInt(time) * 1000)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
    // 是否过期
    is_exprie(row) {
      const time = row.overdue_time
      const now = Date.parse(new Date())
        .toString()
        .substr(0, 10)
      if (time <= now) {
        return '是'
      } else {
        return '否'
      }
    },
    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      // 编辑器
      this.$nextTick(v => {
        if (this.editor) return false
        this.editor = new E('#editor')
        this.editor.config.onchange = html => {
          this.infoform.content = html
        }
        this.editor.config.menus = [
          'head',
          'bold',
          'fontSize',
          'italic',
          'underline',
          'strikeThrough',
          'foreColor',
          'link',
          'justify',
          'image',
          'table',
          'undo',
          'redo'
        ]
        this.editor.create()
      })
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/news/editnews/id/' + id)
      this.infoform = data.data.info
      this.editor.txt.html(data.data.info.content)
      this.users = data.data.ids
      loading.close()
    },
    // 编辑提交
    editsubmit() {
      this.$refs.infoform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/news/editnews',
          this.infoform
        )
        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(data.msg)
            this.editclose()
          } else {
            this.$message.error(data.msg)
          }
        }
      })
    },
    // 关闭编辑弹窗
    editclose() {
      this.$refs.infoform.resetFields()
      this.editor.txt.html('')
      this.editdialogvisible = false
    },
    // 删除操作
    del(id) {
      this.$confirm(this.$t('shanchutips'), this.$t('tishi'), {
        confirmButtonText: this.$t('quding'),
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      }).then(async () => {
        const { data } = await this.$http.post('/admin/news/delnews', {
          id
        })

        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(data.msg)
          } else {
            this.$message.error(data.msg)
          }
        }
      })
    },
    // 图片上传成功操作
    upload(res) {
      this.infoform.image = res.data.url
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
</style>
